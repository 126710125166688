@import "src/config/AppDesign";
.origins-container{
  background: $brown;
  padding: 0 5rem;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h1,p{
    font-family: Geneva-Regular, sans-serif;
    color: white;
  }

  h1{
    text-align: center;
  }

  p{
    text-align: left;
  }

}
