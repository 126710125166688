@import "./src/config/AppDesign";

.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  > img {
    position: absolute;
    border-radius: 50vw;
    opacity: 0.4;
    animation: fadeIn ease-in 2s;
  }

  > h1,
  p {
    font-family: Geneva-Regular, sans-serif;
    color: white;
    z-index: 2;
    animation: fadeIn ease-in 2s;
    text-align: center;
  }

  > a {
    text-decoration: none;
    z-index: 2;
    transition: 0.6s;

    .discord-icon {
      width: 5rem;
      object-fit: contain;
      transition: 0.6s;
      opacity: 0.4;

      &:hover {
        transition: 0.6s;
        opacity: 1;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(1.05);
        opacity: 0.8;
        transition: 0.3s;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 0.1s linear infinite;
  -moz-animation: rotating 0.1s linear infinite;
  -ms-animation: rotating 0.1s linear infinite;
  -o-animation: rotating 0.1s linear infinite;
  animation: rotating 0.1s linear infinite;
}
