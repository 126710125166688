@import '../config/AppDesign';

.header-container{
  padding: 1rem;
  display: flex;
  justify-content: center;
  max-height: 2rem;

  >button{
    border-radius: 5rem;
    outline: none;
    padding: 1rem;
    background-color: transparent;
    color: white;
    border: 0.05rem solid transparent;
    font-family: Geneva-Regular, sans-serif;
    font-size: 1.5rem;
    transition: 0.6s;
    >a{
      text-decoration: none;
      color: inherit;
    }
    &:hover{
      font-size: 1.6rem;
      transition: 0.6s;
    }

    &:active{
      color: $blue;
      transition: 0.3s;
    }
  }
}
