@import '../../../config/AppDesign';

.game-item-container{
  text-align: center;
  margin: 3rem;

    >a{
      text-align: center;
      margin: auto;
      align-self: center;

      >img{
        opacity: 0.6;
        transition: 0.6s;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        width: 40%;
        object-fit: contain;
        padding: 0;
        border-radius: 3rem;

        &:hover{
          opacity: 1.0;
          transition: 0.6s;
          -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
          filter: grayscale(0%);
          transform: scale(1.05);
          border-radius: 1rem;
        }
      }
  }


  >h1, p{
    color: white;
    text-align: center;
    margin: 1rem 0;
  }

}
