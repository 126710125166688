@import './src/config/AppDesign';

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

body{
  background-color: $brown;
}
