@import './src/config/AppDesign';

.hoc-container{
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $brown;

  >.content{
    flex: 1;
    display: flex;
  }

  >.footer{
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

}
