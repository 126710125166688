$green: #8D8741;
$blue: #659DBD;
$tan: #DAAD86;
$brown: #BC986A;
$beige: #FBEEC1;

@font-face {
  font-family: "Geneva-Regular";
  src: local("Geneva Regular"),
  url("/fonts/genevaRegular") format("truetype");
}
